<template>
  <div>
    <div>
      <Content @initCart="initCart" />
    </div>
  </div>
</template>

<script>
import Content from "@/views/web/marketing/content/index.vue";
export default {
  components: {
    //Header,
    Content,
  },
  data: function () {
    return {};
  },
  methods: {
    //初始化
    initCart() {
      this.$emit("initCart");
    },
  },
};
</script>
