<template>
  <div style="width: 100%;">
    <div class="goodsDetail_header_inner">
      
      <div style="font-size: 18px; width: 80%; margin: 0 auto; color: #515a6e">
        <i class="el-icon-s-home"></i>{{ $t("Home") }} >{{ $t("Special Offers") }}
      </div>
    </div>
    <ul class="disQueryUl">
      <li class="title">{{ $t("Special Offers") }}:</li>
      <li
        class="disli"
        v-for="(item, index) in disList"
        :key="index"
        @click="toGoods(item)"
      >
        {{ item.name }}
      </li>
    </ul>

    <div style="width: 80%; margin: 0 auto;">{{ $t("Discounted products do not enjoy") }}</div>
  </div>
</template>

<script>
import { getDisList } from "@/api/goods/goods.js";
export default {
  data() {
    return {
      disList: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      getDisList().then((res) => {
        let resDataList = res.data.data;
        let disList = [];
        disList.push({
          id: "",
          name: "ALL",
        });
        for (let i = 0; i < resDataList.length; i++) {
          disList.push(resDataList[i]);
        }

        this.disList = disList;
      });
    },
    toGoods(val) {
      this.$emit("selDis", val);
    },
  },
};
</script>

<style scoped>
.disQueryUl {
  display: flex;
  /* border: 1px solid #238DA5; */
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 30px;
  line-height: 30px;
}

.disQueryUl .title {
  font-weight: bold;
  font-size: 18px;
}

.disQueryUl li {
  font-size: 14px;
}

.disQueryUl .disli {
  /* border-right: 1px solid #238DA5; */
  min-width: 120px;
  text-align: center;
  color: #515a6e;
  font-size: 18px;
}

.disQueryUl .disli:hover {
  color: white;
  background: #238da5;
  cursor: pointer;
}
</style>
